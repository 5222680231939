<template>
    <div id="StaffDetails">
        <h1 class="page-header dark-text">{{$t('staff_details')}}</h1>
          <div class="alert-info">
            <v-alert text
            class="v-alert-msg"
            dismissible>
            Staff can only be added at facility level.
            </v-alert>
          </div>
        <div id="staffDetailsPage" class="placeholders" v-if="!staffDetailsLoading">
            <staff-details-page/>
        </div>
    </div>
</template>

<script>
import StaffDetailsPage from './components/StaffDetailsPage'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'StaffDetails',
  metaInfo: {
    title: 'Staff Details'
  },
  computed: {
    ...mapState('StaffDetails', [
      'staffDetailsLoading'
    ])
  },
  watch: {
    async $route (to, from) {
      if (to.path === '/dashboard/StaffDetails') {
        await this.loadViewParams()
      }
    }
  },
  async mounted () {
    await this.loadViewParams()
  },
  components: {
    StaffDetailsPage
  },
  methods: {
    ...mapActions('StaffDetails', [
      'loadViewParams'
    ])
  }
}
</script>

<style lang="scss" scoped>
.alert-info {
    margin-top: 16px;
    font-weight: bold;
}
.v-alert-msg {
  color: #2196F3;
}
</style>
<style lang="scss">
.alert-info i {
  color: var(--primary-theme) !important;
}
</style>
