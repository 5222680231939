<template>
    <div id="staffDetailsTableSubContainer">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div v-if="canHierarchyHaveStaffPopulated">
            <div class="alert-info">
                <v-alert text
                color="#2196F3"
                dismissible>
                {{ 'Below is a list of the ' + currentHierarchyType + ' level staff associated with the ' + currentHierarchyType + ' of ' + currentHierarchyName + '.' }}
                <div v-if="!isViewOnly">{{ 'To add staff associated with the whole ' + currentHierarchyType + ', please click on the Add Staff button.' }}<br></div>
                {{ typeSpecificMessage }}
                </v-alert>
            </div>
        </div>
        <v-card v-if="canHierarchyHaveStaffPopulated" class="card">
            <div v-if="!isViewOnly" class="button-container">
                <Button id="addFieldStaffButton" :label="'Add Staff'" @click="addFieldStaff"/>
            </div>
            <Table
              :searchable="true"
              :columns="staffColumns"
              :data="staffColumnsData"
              :title="title1"
              @deleteRowEvent="deleteRowClicked"
              @clickTableEvent="clickTableEvent"
              :addDeleteIcon="!isViewOnly"
              :pageSize="50"
              :loading="false" />
        </v-card>

        <div v-if="subHierarchyType">
            <div v-if="canHierarchyHaveStaffPopulated">
                <div class="alert-info">
                <v-alert text
                color="rgb(26 132 146)"
                dismissible>
                    {{ 'Below is a list of all ' + subHierarchyDisplayTypePlural + ' in ' + currentHierarchyName + '. To add/view/edit staff specific to a ' + subHierarchyType + ', please click on the name of that ' + subHierarchyType + '.' }}
                </v-alert>
                </div>
            </div>
        </div>
      <v-card v-if="hierarchyColumnsData && hierarchyColumnsData.length != 0" class="card">
       <Table
        :searchable="true"
        :columns="hierarchyColumns"
        :data="hierarchyColumnsData"
        @deleteRowEvent="deleteRowClicked"
        @clickTableEvent="clickTableEvent"
        :title="title2"
        :pageSize="50"
        :loading="false" />
      </v-card>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import Breadcrumbs from '../../../../shared/components/Breadcrumbs.vue'
import Table from '../../../../shared/components/Table.vue'
import Button from '../../../../shared/components/Button.vue'

export default {
  name: 'StaffDetailsPage',
  components: {
    Breadcrumbs,
    Table,
    Button
  },
  mounted () {},
  computed: {
    ...mapState('StaffDetails', [
      'breadcrumbs',
      'canHierarchyHaveStaffPopulated',
      'currentHierarchyDisplayType',
      'currentHierarchyId',
      'currentHierarchyName',
      'currentHierarchyType',
      'typeSpecificMessage',
      'subHierarchyType',
      'subHierarchyDisplayTypePlural',
      'isViewOnly',
      'staffColumnsData',
      'staffColumns',
      'hierarchyColumnsData',
      'hierarchyColumns'
    ]),
    title1 () {
      return 'Staff for ' + this.currentHierarchyDisplayType + ' of ' + this.currentHierarchyName
    },
    title2 () {
      return 'List of ' + this.subHierarchyDisplayTypePlural + ' in ' + this.currentHierarchyName
    }
  },
  methods: {
    ...mapActions('AddStaff', [
      'deleteFieldStaff',
      'setViewOnly',
      'setPreviousHierarchyId'
    ]),
    clickTableEvent (column, item) {
      if (column === 'StaffName') {
        this.setPreviousHierarchyId(this.currentHierarchyId)
        this.setViewOnly(this.isViewOnly)
        this.$router.push(`/dashboard/UpdateStaff/${item.StaffId}`)
      } else if (column === 'HierarchyName') {
        window.open('/dashboard/StaffDetails/' + item.ClickableLink, '_self')
      }
    },
    async deleteRowClicked (item) {
      const alertMessage = 'If you delete this staff he/she will no longer receive alerts for all patients in this region. This operation cannot be undone. Are you sure?'
      if (confirm(alertMessage) === true) {
        const params = { fieldStaffId: item.StaffId }
        await this.deleteFieldStaff({ params: params })
        this.$router.go()
      }
    },
    addFieldStaff () {
      this.setPreviousHierarchyId(this.currentHierarchyId)
      this.setViewOnly(this.isViewOnly)
      this.$router.push(`/dashboard/AddStaff/${this.currentHierarchyId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-info {
  margin-top: 16px;
  font-weight: bold;
}
.multi-cal-table {
  font-size: 14px;
}
.table_container {
  margin: 0px !important;
}
.card {
  padding: 15px;
  border-radius: 8px;
  background-color: var(--background-color-primary-light-dark);
}
.button-container {
  justify-content: flex-end;
  display: flex;
}
</style>
